import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useCallback,
} from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { AppContext } from '../../context/appContext';
import ResultDisplay from '../resultDisplay/ResultDisplay';
import Button from '../button/Button';
import GateDataHandler from '../../controller/eventHandler/GateHandler';
import OfflineBatchHandler from '../../controller/syncHandler/OfflineBatchHandler';
import ScanInHandler from '../../controller/scanHandler/ScanInHandler';
import AppModeHandler from '../../controller/appModeHandler/AppModeHandler';
import { fetchApi } from '../../AjaxUtil';
import Configs from '../../Configs';
import * as constants from '../../constants/constants';
import '../../styles/manualEntry.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import PairScannerNameHandler from '../../controller/authHandler/PairScannerNameHandler';
import ScannerUserHandler from '../../controller/authHandler/ScannerUserHandler';
import { validate } from '../../offlineValidation/validate';
import { useErrorHanlder } from '../../context/errorContext';

const ValidatorEntry = function () {
  const { barcode, setBarcode, reason, setReason, token, scannerUser, fromPath } =
    useContext(AppContext);
  const history = useHistory();
  const { t } = useTranslation();
  const { register, handleSubmit, reset } = useForm();
  const [ticketCode, setTicketCode] = useState('');
  const [adType, setAdType] = useState(
    ScanInHandler.getIsScanInInfo() === 'true'
      ? constants.AdmissionTypeIn
      : constants.AdmissionTypeOut,
  );
  const [isShowResult, setIsShowResult] = useState(false);
  const [isDisableBtn, setIsDisableBtn] = useState(true);
  const [btnDisabledStatus, setBtnDisabledStatus] = useState();
  const [source, setSource] = useState();
  const audioRef = useRef();
  // get date time
  const tzoffset = new Date().getTimezoneOffset() * 60000; // offset in milliseconds
  const [gateInfo] = useState(GateDataHandler.getGateInfo());
  const [appMode] = useState(AppModeHandler.appMode());
  const [type, setType] = useState(constants.ManualEntryTicket);
  const [additionalMsg, setAdditionalMsg] = useState('');
  const { showErrorNotification } = useErrorHanlder();

  useEffect(() => {
    // if override
    if (reason && barcode) {
      //setIsShowResult(true);
      // start of API - for online only
      if (appMode === constants.AppModeOnline) {
        // valid barcode from server if connection is available
        const param = {
          barcode: barcode.barcode,
          admissionType: adType, // 1 = in, 2 = out
          gate: gateInfo,
          overrideReason: reason,
        };
        fetchApi(
          Configs.SCAN.API_METHOD,
          Configs.SCAN.API_PATH,
          onSuccessCallbackOverride,
          param,
          onErrorCallbackOverride,
        );
      }
    } else {
      // Returned from override but did not make a selection
      if (fromPath && fromPath === Configs.VALIDATORENTRY.PATH && barcode && barcode.barcode!=undefined) {
        document.querySelector('.manual-override').classList.add('active');
      }
      setIsShowResult(false);
    }

    // Disable when no offline
    setType(constants.ManualEntryBarcode);
  }, []);

  useEffect(() => {
    if (isDisableBtn || !ticketCode) {
      setBtnDisabledStatus(true);
    } else {
      setBtnDisabledStatus(false);
    }
  }, [isDisableBtn]);

  useEffect(() => {
    if (ScanInHandler.getIsScanInInfo() === 'true') {
      setAdType(constants.AdmissionTypeIn);
    } else {
      setAdType(constants.AdmissionTypeOut);
    }
  }, [ScanInHandler.getIsScanInInfo()]);


  // go to scanner
  const backHandler = () => {
    setReason();
    history.push(Configs.SCAN.PATH);
  };

  const handleKeyDown = (event) => {
    var ticketCode =  document.getElementById("ticketcode").value;
    if (ticketCode != "" && event.key === 'Enter') {
      setIsDisableBtn(false);
      setTimeout(() => {
        document.querySelector(".manual-validate-btn").focus();
        document.querySelector(".manual-validate-btn").click();
      }, 100);

      // Add to reset
      setReason();
      setIsShowResult(false);
      setAdditionalMsg('');
    } else {
      setIsDisableBtn(true);
    }
  };

  const handleTicketCodeChange = (event) => {
    // if error is still showing, do not proceed
    if (isShowResult && additionalMsg) {
      // do nothing
      document.getElementById("ticketcode").value = "";
      document.getElementById("ticketcode").focus();
    } else {
      // Reset fields to search again
      //setIsDisableBtn(true);

      setTicketCode(event.target.value.trim());
      /*
      if (event.target.value.trim() && event.key === 'Enter') {
        setIsDisableBtn(false);
        setTimeout(() => {
          document.querySelector(".manual-validate-btn").focus();
          document.querySelector(".manual-validate-btn").click();
        },300);
      } else {
        setIsDisableBtn(true);
      }*/
    }
  };

  const handleOffFocus = (event) => {
    //console.log("handleOffFocus: " + event.currentTarget.id);
    if (event.currentTarget.id === 'back-btn' || event.currentTarget.id === 'override-btn' || event.currentTarget.id === 'validate-btn') {
      // Do nothing
    } else {
      document.getElementById("ticketcode").focus();
    }
  };

  const overrideHandler = () => {
    if (
      document.querySelector('.manual-override').classList.contains('active')
    ) {
      history.push(Configs.OVERRIDE.PATH, { from: location.pathname });
    }
  };

  const onSuccessCallbackOverride = (response) => {
    // handle success
    // console.log('manualEntry override succ', response);
    setBarcode(response);
    setIsShowResult(true);
    if (navigator.vibrate) {
      // vibration API supported
      navigator.vibrate(Configs.SCAN.SUCCESS_VIBRATE_DURATION);
    }
    // chg status & hide result after X seconds
    setTimeout(() => {
      /* do not remove
      setReason();
      setIsShowResult(false);
      setIsDisableBtn(false);
      history.push(Configs.SCAN.PATH);
      */
      document.querySelector('.manual-override').classList.remove('active');
    }, Configs.OVERRIDE.SUCCESS_DISPLAY_DURATION);
  };

  const onErrorCallbackOverride = (err, status, message) => {
    showErrorNotification({message: `${status}: ${message}` || err});
  };

  const onSuccessCallback = (response) => {
    // handle success
    // console.log('manualEntry succ', response, status);
    setIsShowResult(true);
    setBarcode(response); // set barcode variable with response data
    if (response.scanResult === constants.ScanSuccess) {
      // success
      playSound(true);
      if (navigator.vibrate) {
        // vibration API supported
        navigator.vibrate(Configs.SCAN.SUCCESS_VIBRATE_DURATION);
      }
      document.querySelector('.manual-override').classList.remove('active');
    } else {
      setAdditionalMsg(t('validatorEntry_taptoproceed'));

      // error
      playSound(false);
      if (navigator.vibrate) {
        // vibration API supported
        navigator.vibrate(Configs.SCAN.FAIL_VIBRATE_DURATION);
      }
      if (!response.blockOverride) {
        document.querySelector('.manual-override').classList.add('active');
      } else {
        document.querySelector('.manual-override').classList.remove('active');
      }
    }
    // chg status & hide result after X seconds
    /* do not hide results
    setTimeout(() => {
      setIsShowResult(false);
      setIsDisableBtn(false);
      if (response.scanResult === constants.ScanSuccess) {
        history.push(Configs.SCAN.PATH);
      }
    }, Configs.VALIDATORENTRY.RESULT_DISPLAY_DURATION);
    */
  };

  const onErrorCallback = (err, status, message) => {
    showErrorNotification({message: `${status}: ${message}` || err});
  };

  const onSubmit = useCallback(
    async (data) => {
      setIsDisableBtn(true);
      console.log("validating..." + ticketCode);

      let param = {
          barcode: ticketCode,
          admissionType: adType, // 1 = in, 2 = out
          gate: gateInfo,
          // overrideReason: "overrideReason",
        };
        if (appMode === constants.AppModeOnline) {
          // valid barcode from server if connection is available
          await fetchApi(
            Configs.SCAN.API_METHOD,
            Configs.SCAN.API_PATH,
            onSuccessCallback,
            param,
            onErrorCallback,
          );
          document.getElementById("ticketcode").value = "";
          document.getElementById("ticketcode").focus();
        } else if (appMode === constants.AppModeOffline) {
          offlineValidate(param, data);
          document.getElementById("ticketcode").value = "";
          document.getElementById("ticketcode").focus();
        } else if (appMode === constants.AppModeEmergency) {
          emergencyMode(param, data);
          document.getElementById("ticketcode").value = "";
          document.getElementById("ticketcode").focus();
        }
    },

    [appMode, barcode, type, ticketCode],
  );

  const emergencyMode = async (param, data) => {
    // emergency - TODO
    // no connection, save barcode to local storage
    const emergencyParam = {
      recordId: Date.now(),
      barcodeId: constants.Validation.barcodeId, // if have imported barcode, else empty
      barcode: data.ticketcode,
      admissionType: adType, // 1 = in, 2 = out
      gateId: gateInfo,
      scanResult: 1, // always success
      scanReason: '', // reason for succ / fail - should not show anything
      overrideReason: null,
      admissionRuleId: constants.Validation.admissionRuleId,
      scanDate: new Date().toISOString(),
      scannerProfile: PairScannerNameHandler.getScannerName(),
      scanBy: scannerUser,
      scanCheck: 0, // based on rule if scan check was required
      scanMode: appMode,
      synced: false,
      admissionCount: constants.Validation.admissionCount,
      // valid: 'valid',
    };
    setBarcode(emergencyParam);
    SaveDataToLocalStorage(emergencyParam);
    setIsShowResult(true);
    playSound(true);
    // chg status & hide result after X seconds
    /* do not hide results
    setTimeout(() => {
      setIsShowResult(false);
      setIsDisableBtn(false);
      history.push(Configs.SCAN.PATH);
    }, Configs.VALIDATORENTRY.RESULT_DISPLAY_DURATION);
     */
  };

  const offlineValidate = async (param, data) => {
    // offline - TODO
    // no connection, save barcode to local storage
    let message;
    let override;
    let result = 1;
    let scanReason = null;
    param = {
      ...param,
      scannerProfile: PairScannerNameHandler.getScannerName(),
      scannerUser: ScannerUserHandler.getScannerUser(),
    };
    let validateResult = await validate(param);
    if (validateResult.error || validateResult.override) {
      if (validateResult.override && validateResult.override === true) {
        override = true;
        message = param.admissionType === constants.AdmissionTypeIn ? constants.AllowEntry : constants.AllowExit;
        result = 1;
      } else {
        message = t(validateResult.error?.type ? validateResult.error.type : validateResult.error);
        result = 0;
        document.querySelector('.manual-override').classList.add('active');
      }
    } else {
      if (!validateResult.admissionRun) {
        validateResult.error = {priority: 1, type: 'validate_admission_barcode_invalid'};
        message = t(validateResult.error?.type ? validateResult.error.type : validateResult.error);
        result = 0;
        document.querySelector('.manual-override').classList.add('active');
      } else {
      if (param.admissionType === constants.AdmissionTypeIn) {
          //console.log(constants.Validation.admissionCount);
        if (constants.Validation.admissionCount > 1) {
          scanReason = `${constants.Validation.ticketAttribute?.attributeName} (${constants.Validation.admissionCount})`;
          } else {
            scanReason = constants.Validation.ticketAttribute?.attributeName;
          }
        message = constants.AllowEntry;
      } else message = constants.AllowExit;
      result = 1;
    }
    }

    const offlineParam = {
      recordId: Date.now(),
      barcodeId: constants.Validation.barcodeId, // if have imported barcode, else empty
      barcode: constants.Validation.barcode,
      admissionType: adType, // 1 = in, 2 = out
      gateId: gateInfo,
      scanResult: result, // always success
      scanReason: scanReason,
      scanMessage: message,
      overrideReason: override ? param.overrideReason : null,
      admissionRuleId: constants.Validation.admissionRuleId,
      scanDate: new Date().toISOString(),
      scannerProfile: PairScannerNameHandler.getScannerName(),
      scanBy: scannerUser,
      scanCheck: constants.Validation.scanCheck, // based on rule if scan check was required
      scanMode: appMode,
      synced: false,
      admissionCount: constants.Validation.admissionCount,
      // valid: 'valid',
    };
    setBarcode(offlineParam);
    SaveDataToLocalStorage(offlineParam);
    setIsShowResult(true);
    if (offlineParam.scanResult === constants.ScanSuccess) {
      playSound(true);
      if (navigator.vibrate) {
        // vibration API supported
        navigator.vibrate(Configs.SCAN.SUCCESS_VIBRATE_DURATION);
      }
      setTimeout(() => {
        /* do not remove
        setReason();
        setIsShowResult(false);
        setIsDisableBtn(false);
        history.push(Configs.SCAN.PATH);
        */
        document.querySelector('.manual-override').classList.remove('active');
      }, Configs.OVERRIDE.SUCCESS_DISPLAY_DURATION);
    } else {
      playSound(false);
      if (navigator.vibrate) {
        // vibration API supported
        navigator.vibrate(Configs.SCAN.FAIL_VIBRATE_DURATION);
      }
    }
    // chg status & hide result after X seconds
    /* do not hide results
    setTimeout(() => {
      setIsShowResult(false);
      setIsDisableBtn(false);
      if (offlineParam.scanResult === constants.ScanSuccess) {
        history.push(Configs.SCAN.PATH);
      }
    }, Configs.VALIDATORENTRY.RESULT_DISPLAY_DURATION);
     */
  };

  const clearManualInput = () => {
    setTicketCode('');
    setIsDisableBtn(true);
  };

  function SaveDataToLocalStorage(data) {
    let offlineData = [];
    // PofflineDatarse the serialized data back into an aray of objects
    offlineData = JSON.parse(OfflineBatchHandler.getOfflineBatchProcessData()) || [];
    // Push the new data (whether it be an object or anything else) onto the array
    offlineData.push(data);
    // Alert the array value
    // alert(offlineData); // Should be something like [Object array]
    // Re-serialize the array back into a string and store it in localStorage
    OfflineBatchHandler.setOfflineBatchProcessData(offlineData);
  }

  // play notify sound after detected barcode
  const playSound = (isSuccess) => {
    const scanStatus = isSuccess
      ? Configs.SCAN.SUCCESS_SOUND
      : Configs.SCAN.FAIL_SOUND;
    setSource(scanStatus);
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.load();
      audioRef.current.play();
    }
  };

  const handleTap = () => {
    setIsShowResult(false);
    setIsDisableBtn(false);
  };

  return (
    <form className='manual-form' onClick={handleOffFocus}>
      <div className='manual-container'>
        <div className='manual-top'>
          <div
              id='back-btn'
            className='back-btn'
            onClick={backHandler}
            onKeyPress={backHandler}
            role='button'
            tabIndex='0'
          >
            {t('validatorEntry_backText')}
          </div>
          <div className='manual-details'>{t('validatorEntry_headerText')}</div>
          <div
              id='override-btn'
            className='manual-override'
            onClick={overrideHandler}
            onKeyPress={overrideHandler}
            role='button'
            tabIndex='0'
          >
            {t('validatorEntry_headerOverride')}
          </div>
        </div>

        <div className='manual-form-box'>
          <div className='manual-ticket-group'>
            {ticketCode.length > 0 && (
              <FontAwesomeIcon
                icon={faTimes}
                className='manual-input-clear'
                onClick={clearManualInput}
              />
            )}
            <input
              {...register('ticketcode', { required: true })}
              name='ticketcode'
              id='ticketcode'
              className='form-ticketcode'
              placeholder={t('manualEntry_placeholder')}
              onChange={handleTicketCodeChange}
              onKeyDown={handleKeyDown}
              //value={ticketCode}
              autoFocus
              onFocus={e => e.currentTarget.select()}
            />
          </div>
        </div>

        {isShowResult && barcode.scanReason !== undefined && (
         <div className='result-wrapper' onClick={handleTap}>
          <ResultDisplay
            cName={`result-display-container ${
              barcode.scanResult === 1 ? 'valid' : 'invalid'
            } ${reason ? 'reason' : ''} ${
              barcode.scanCheck === constants.ScanCheckTrue ? 'scanCheck' : ''
            }`}
            style={
              barcode.scanResult === 1 && barcode.appResponseColor && barcode.appResponseColor !== '#2ACA59'
                ? { backgroundColor: barcode.appResponseColor }
                : {}
            }
            additionalMsg={additionalMsg}
          />
         </div>
        )}
        <Button
            id='validate-btn'
            cName='manual-validate-btn'
            disable={btnDisabledStatus}
            funct={handleSubmit(onSubmit)}
            text={t('manualEntry_btnText')}
        />
      </div>

      <audio controls ref={audioRef}>
        <track kind='captions' />
        <source src={source} type='audio/mpeg' />
      </audio>
    </form>
  );
};

export default ValidatorEntry;
